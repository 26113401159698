<template>
  <LineChartGenerator
    v-if="isMounted"
    id="my-chart-id"
    :chartData="chartDataComputed"
    :styles="{ width:'100%'}"
  />
</template>

<script>
import { Line as LineChartGenerator } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, LineElement, LinearScale, CategoryScale, PointElement } from 'chart.js'

ChartJS.register(  Title,  Tooltip,  Legend,  LineElement,  LinearScale,  CategoryScale,  PointElement)

export default {
  name: 'LineChart',
  components: { LineChartGenerator },
  props: {
    priceHistory: {
        required: true
      },
    title: {
      default: '價錢走勢'
    },
    isActive: {
      default: false
    }

  },
  data() {
    return {
      chartOptions: {
            responsive: true,
            maintainAspectRatio: true
          },
      isMounted: false
    }
  },
  mounted: function () {

    //push price each second
    setTimeout(() => {
      this.isMounted = true;
    }, 50);

  },
  computed: {
    chartDataComputed() {
      return {
        labels: this.priceHistory.map(p=>this.formatDate(p.date)),
        datasets: [
          {
            label: this.title,
            backgroundColor: '#f87979',
            data: this.priceHistory.map(p=>p.price)
          }
        ]
      };
    },

  },
  methods: {
    formatDate(date) {
      // if date is a date object, convert it to string
      if (date instanceof Date) {
        return date.toISOString().substring(0,10);
      }
      else return date.substring(0,10);
    }
  }
}
</script>